import React from 'react';
import { useIntl } from 'react-intl';
import { ErrorMessage, useField } from 'formik';
import { FormInputWrapper, Text } from './';


export const FormInput = props => {

    const intl = useIntl();
    const [field, meta] = useField(props.name);
    const Tag = props.tag || 'input';

    return(
        <FormInputWrapper
            className={'relative ' + (props.className || '')}
            width={props.width}
            meta={meta}
            name={props.name}
            label={props.label}
            labelPosition={props.labelPosition}
            labelWidth={props.labelWidth}
            noLabel={props.noLabel}
            countryCode={props.countryCode}
        >
            {props.icon ?
                <span className="pointer-events-none w-8 h-8 absolute top-1/2 transform -translate-y-1/3 left-0">{props.icon}</span>
                : null
            }
            <Tag
                {...field}
                {...(props.textareaRows ? {rows: props.textareaRows} : {})}
                {...(props.format ? {format: props.format} : {})}
                type={props.type ? props.type : 'text'}
                name={props.name}
                id={props.name}
                onBlur={props.onBlur}
                onClick={props.onClick}
                disabled={props.disabled}
                {...(props.max ? {maxlength: props.max} : {})}
                {...(props.value ? {value: props.value} : {})}
                placeholder={props.placeholder ? intl.formatMessage({id: props.placeholder}) : ''}
                className={'h-12 border-bk-grey-light rounded-md placeholder-gray-400 shadow-[0px_1px_2px_0px_rgba(0, 0, 0, 0.05)] text-bk-black focus:ring-transparent focus:outline-none ' + ((meta.value === '' && 'text-gray-400 ') || '') +
                'w-full sm:text-sm ' + ((meta.touched && meta.error) ? 'border-red-500 focus:border-red-600 focus:ring-red-600 ' : '') + (props.icon ? 'pl-10 ' : '') + (props.inputClassName || '')}
            />
        </FormInputWrapper>
    );
};

// FormInput.propTypes = {
//     name: PropTypes.string.isRequired,
//     placeholder: PropTypes.string,
//     value: PropTypes.string,
//     type: PropTypes.string,
//     label: PropTypes.string,
//     className: PropTypes.string,
//     tag: PropTypes.string,
//     format: PropTypes.string,
//     textareaRows: PropTypes.number,
//     onClick: PropTypes.object,
//     disabled: PropTypes.bool,
//     noLabel: PropTypes.bool,
//     updateMeta: PropTypes.func
// };

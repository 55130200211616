import React from 'react';
import { FormError, FormLabel } from "./";
import { Text } from "./"
import { ErrorMessage } from 'formik';


export const FormInputWrapper = (props) => {

    // Other half of props.labelWidth
    const inputWidth = {
        'w-1/2': 'w-1/2',
        'w-1/3': 'w-2/3',
        'w-1/4': 'w-3/4',
        'w-1/5': 'w-4/5',
        'w-1/6': 'w-5/6',
        'w-2/3': 'w-1/3',
        'w-2/4': 'w-2/4',
        'w-2/5': 'w-3/5',
        'w-2/6': 'w-4/6',
        'w-3/4': 'w-1/4',
        'w-3/5': 'w-2/5',
        'w-3/6': 'w-3/6',
        'w-4/5': 'w-1/5',
        'w-4/6': 'w-2/6',
        'w-5/6': 'w-1/6',
        'w-auto': 'w-full'
    }

    return (
        <div className={(props.width || '') + (props.className ? ' ' + props.className : '')}>
            <div className={(props.labelPosition && props.labelWidth ? ' flex items-center h-full' : 'h-full')}>
                {(props.label && !props.noLabel) &&
                    <div className={'h-auto ' + (props.labelPosition && props.labelWidth ? props.labelPosition === 'left' ? 'order-1 ' + props.labelWidth : 'order-2 :' + props.labelWidth : '')}>
                        <FormLabel label={props.label} name={props.name} labelPosition={props.labelPosition}/>
                    </div>
                }
                
                <div className={'flex items-center h-full ' + (props.labelPosition && props.labelWidth ? props.labelPosition === 'left' ? 'order-2 ' + inputWidth[props.labelWidth] : 'order-1 ' + inputWidth[props.labelWidth] : '')}>
                    {props.countryCode && 
                        <Text sm className="items-center justify-center h-12 px-2 flex border rounded-md sm:text-sm mr-2">
                            {props.countryCode}
                        </Text>
                    }
                    {props.children}
                </div>
                <div>
                    <Text left sm className="text-red-500 sm:text-sm">
                        <ErrorMessage name={props.name} />
                    </Text>
                </div>
            </div>
        </div>
    )
}

// FormInputWrapper.propTypes = {
//     name: PropTypes.string.isRequired,
//     label: PropTypes.string.isRequired,
//     meta: PropTypes.object.isRequired,
//     className: PropTypes.string,
//     labelPosition: PropTypes.oneOf(['left', 'right']),
//     labelWidth: PropTypes.oneOf(['w-1/2', 'w-1/3', 'w-1/4', 'w-1/5', 'w-1/6', 'w-2/3', 'w-2/4', 'w-2/5', 'w-2/6', 'w-3/4', 'w-3/5', 'w-3/6', 'w-4/5', 'w-4/6', 'w-5/6', 'w-auto'])
// }

import { BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip } from 'chart.js'
import { Bar } from 'react-chartjs-2';
import { Block, Button, Icon, InputSelect, Line, Modal, Navbar, Table, Text, Footer } from '../components';
import { ReactComponent as Reserved } from '../assets/img/icons/Reserved.svg';
import { ReactComponent as Wallet } from '../assets/img/icons/Wallet.svg';
import React, { useEffect, useState } from 'react';
import { api, formatDate, formatNumber, getCurrentDateString, useAppSelector } from '../_library';
import { useIntl } from 'react-intl';
import CardImg from '../assets/img/CardImage.png';

type Props = {};

export const Dashboard: (props: Props) => JSX.Element = () => {

    const totalBalance = '2,885';
    const availableAmount = '3,000';
    const reservedAmount = '150';
    const [transactionToView, setTransactionToView] = useState<null | {[key: string]: any}>(null);
    const [transactionToViewDetails, setTransactionToViewDetails] = useState<null | {[key: string]: any}>(null);
    const [chartData, setChartData] = useState({months: [], data: []});
    const { currency } = useAppSelector(state => state.user);
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    const todayDate = `${year}-${month}-${day}`;
    const yearFirstDate = `${year}-01-01`;
    const [balance, setBalance] = useState(0);
    const [card, setCard] = useState({
        logo: '',
        status: '',
        yearlyLimit: 0,
        name_on_card: '',
        id: '',
        card_expiry_date: '',
    });
    const [creditLimit, setCreditLimit] = useState(0);
    const [showTopUpModal, setShowTopUpModal] = useState(false);
    const intl = useIntl();

    ChartJS.register(
        CategoryScale,
        LinearScale,
        BarElement,
        Title,
        Tooltip,
        Legend
    );

    const options = {
        responsive: true,
        plugins: {
            legend: {
                // position: 'top' as const,
                // labels: {
                //     color: 'rgba(31, 41, 55, 1)',
                //     usePointStyle: true,
                //     align: 'start' as const,
                // }
                display: false
            }
        },
        scales: {
            x: {
                grid: {
                    display: false
                },
                title: {
                    display: true,
                    text: intl.formatMessage({id: 'bk.dashboard.graph.axis_title'}),
                    padding: 10,
                }
            }
        }
    };

    const convertToMonthNames = (monthsDate: any) => {
        const monthNames = [
            'Jan.', 'Feb.', 'Mar.', 'Apr.', 'May', 'Jun.',
            'Jul.', 'Aug.', 'Sep.', 'Oct.', 'Nov.', 'Dec.'
        ];

        return monthsDate.map((dateString: any) => {
            const [year, month] = dateString.split('-');
            const monthIndex = parseInt(month, 10) - 1;
            return monthNames[monthIndex];
        });
    };

    let data = {
        labels: convertToMonthNames(chartData.months),
        datasets: [
            {
                id: 1,
                // label: 'Product 1',
                data: chartData.data,
                backgroundColor: 'rgba(22, 91, 170, 1)',
                barThickness: 14,
                borderRadius: 5
            }
        ],
    };

    const handleFilterChange = (value: any) => {
        const fromDate = value !== '1y' && value > 0 ? `${year}-${String(value).padStart(2, '0')}-${day}` : yearFirstDate;
        api.get(`/transactions/chart?dateFrom=${fromDate}&dateTo=${todayDate}`)
            .then(response => {
                setChartData(response.data);
            })
            .catch();
    };

    const renderTransactionDetailsModalRow = (label: string, value: any) => (
        <>
            <Text
                className="text-bk-grey-100"
                left={true}
                label={label}
            />
            <Text left={true} className="text-md font-medium mt-1 mb-2">{value}</Text>
        </>
    );

    const renderTopUpModalRow = (label: string, value: string) => (
        <div className="flex justify-between my-2">
            <Text
                className="text-bk-grey-100"
                label={label}
            />
            <Text className="text-bk-green">{value}</Text>
        </div>
    );

    useEffect(() => {
        api.get(`/transactions/chart?dateFrom=${yearFirstDate}&dateTo=${todayDate}`)
            .then(response => {
                setChartData(response.data);
            })
            .catch();
    }, []);

    useEffect(() => {
        if (transactionToView === null) {
            return;
        }

        api.get(`/transactions/${transactionToView.id}`)
            .then(response => {
                setTransactionToViewDetails(response.data);
            })
            .catch();
    }, [transactionToView]);

    useEffect(() => {
        api.get('/employees/balance')
            .then(response => {
                setBalance(response.data.balance);
                setCreditLimit(response.data.credit_limit);
            })
            .catch();
    }, []);

    useEffect(() => {
        api.get('/employees/cards')
            .then(response => {
                setCard(response.data);
            })
            .catch();
    }, []);


    return (
        <div className="min-h-screen bg-bk-grey pb-20">
            <Navbar />
            <div className="md:flex">
                <Block className="md:w-1/3 w-full h-494 bg-white mr-4 ml-8">
                    <Text className="font-bold" twoXL label="bk.dashboard.your_wallet" />
                    <Line dotted className="mt-6 mb-4"/>
                    <div className="mb-3">
                        <Text grey label="bk.dashboard.total_balance" />
                    </div>
                    <div className="mb-4">
                        <span className="text-4xl font-bold">
                            {(Math.round(balance * 100) / 100).toFixed(2)} EUR
                        </span>
                    </div>
                    <div className="flex-none w-[340px] relative">
                        <img alt="card" src={CardImg} className="max-w-[340px]" />
                        <div className="absolute top-0 pl-4 pt-3 w-full">
                            <div className="flex justify-between pr-4">
                                <div>
                                    <Text label="bk.user.card.annual_limit" left={true} className="text-bk-grey-100" sm={true} />
                                    <Text left={true} className="text-white" xs={true}>
                                        {formatNumber(card.yearlyLimit, true)}
                                        {currency === 'EUR' ? <>&euro;</> : <>{currency}</>}
                                    </Text>
                                </div>
                                <div>
                                    {card.logo ?
                                        <img src={card.logo} alt="cardLogo" className="max-w-[100px] max-h-[25px]" />
                                        : null
                                    }
                                </div>
                            </div>
                            <Text left={true} xl={true} bold={true} className="text-white mt-20 pt-1">
                                {card.name_on_card}
                            </Text>
                            <div className="flex">
                                <Text className="text-white" xs={true}>****&nbsp;{card.id.slice(-4)}</Text>
                                <Text className="text-white ml-8" xs={true}>
                                    {formatDate(
                                        new Date(card.card_expiry_date),
                                        false,
                                        false,
                                        'MM/YYYY',
                                    )}
                                </Text>
                            </div>
                        </div>
                    </div>
                    <Line dotted className="mt-6 mb-4"/>
                    <div>
                        <div className="grid gap-4 grid-cols-2 grid-rows-1">
                            <div className="h-32 bg-bk-yellow rounded-lg">
                                <div className="p-4">
                                    <Wallet />
                                </div>
                                <Text sm green label="bk.dashboard.available_amount" className="pl-4" />
                                <div className="mb-4">
                                    <span className="text-xl pl-4 text-bk-green-700 font-bold">
                                        {creditLimit} EUR
                                    </span>
                                </div>
                            </div>
                            <div className="h-32 bg-bk-pink-100 rounded-lg">
                                <div className="p-4">
                                    <Reserved />
                                </div>
                                <Text sm pink label="bk.dashboard.reserved_amount" className="pl-4" />
                                <div className="mb-4">
                                    <span className="text-xl pl-4 text-bk-pink-200 font-bold">
                                        {Math.abs(creditLimit - balance).toFixed(2)} EUR
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </Block>
                <Block className="w-2/3 h-494 bg-white ml-6 mr-8">
                    <div className="flex justify-between min-h-[50px] items-center mb-8">
                        <div>
                            <Text className="font-bold" twoXL label="bk.dashboard.monthly_transactions" />
                        </div>
                        <div>
                            <InputSelect
                                notInForm={true}
                                options={[
                                    {name: '12 months', value: '1y'},
                                    {name: '6 months', value: today.getMonth() - 5},
                                    {name: '3 months', value: today.getMonth() - 2},
                                    {name: '1 month', value: today.getMonth()}
                                ]}
                                selected="1y"
                                onChange={(value: any) => handleFilterChange(value)}
                                noInput={true}
                            />
                        </div>
                    </div>
                    <div>
                        <Bar options={options} data={data} />
                    </div>
                </Block>
            </div>
            <Block className="h-full bg-white mr-8 ml-8 mt-8">
                <Table
                    title="bk.dashboard.last_transactions"
                    dataLoader={{
                        apiCall: {
                            path: '/transactions',
                            rowsNameInResponse: 'transactions',
                            totalPagesNameInResponse: 'pages',
                            totalRecordsNameInResponse: 'total',
                            dateFrom: getCurrentDateString(true, 1),
                            dateTo: getCurrentDateString(true)
                        }
                    }}
                    columns={[
                        {name: 'beneficiarOrRemitter', label: 'bk.dashboard.last_transactions.beneficiary'},
                        {name: 'amount', label: 'bk.dashboard.last_transactions.amount', type: 'money'},
                        {name: 'executedAt', label: 'bk.dashboard.last_transactions.executed_at', type: 'date', withTime: false}
                    ]}
                    actions={[
                        {
                            label: 'bk.dashboard.last_transactions.details',
                            onClick: setTransactionToView
                        }
                    ]}
                    periodSelect={true}
                />
            </Block>
            {transactionToView !== null && transactionToViewDetails !== null ?
                <Modal
                    isOpen={true}
                    toggle={() => {
                        setTransactionToView(null);
                        setTransactionToViewDetails(null);
                    }}
                    title="bk.dashboard.last_transactions.modal.title"
                    content={
                        <div>
                            <div className="mb-8">
                                {renderTransactionDetailsModalRow(
                                    'bk.dashboard.last_transactions.modal.amount',
                                    <Text className={(transactionToViewDetails.amount < 0 ? 'text-bk-red' : 'text-bk-green')}>
                                        {transactionToViewDetails.amount} {currency}
                                    </Text>
                                )}
                                {renderTransactionDetailsModalRow(
                                    'bk.dashboard.last_transactions.modal.executed_at',
                                    formatDate(transactionToViewDetails.executedAt, false)
                                )}
                                {renderTransactionDetailsModalRow(
                                    'bk.dashboard.last_transactions.modal.reference',
                                    transactionToViewDetails.reference
                                )}
                                {renderTransactionDetailsModalRow(
                                    'bk.dashboard.last_transactions.modal.merchant_category_code',
                                    transactionToViewDetails.merchantCategoryCode
                                )}
                                {renderTransactionDetailsModalRow(
                                    'bk.dashboard.last_transactions.modal.merchant_id',
                                    transactionToViewDetails.merchantId
                                )}
                                {renderTransactionDetailsModalRow(
                                    transactionToViewDetails.amount < 0 ?
                                        'bk.dashboard.last_transactions.modal.beneficiary' :
                                        'bk.dashboard.last_transactions.modal.remitter',
                                    transactionToViewDetails.beneficiarOrRemitter
                                )}
                                {renderTransactionDetailsModalRow(
                                    'bk.dashboard.last_transactions.modal.description',
                                    transactionToViewDetails.description
                                )}
                            </div>
                            <div className="flex justify-end">
                                <Button
                                    small={true}
                                    secondary={true}
                                    onClick={() => {
                                        setTransactionToView(null);
                                        setTransactionToViewDetails(null);
                                    }}
                                    label="bk.dashboard.last_transactions.modal.btn.close"
                                />
                            </div>
                        </div>
                    }
                /> : null
            }
            <Footer />
        </div>
    );
};
